<template lang="pug">
div
  van-sticky
    van-dropdown-menu
      van-dropdown-item(v-model='active', :options='option1' 
        @change="onChange")
  quote-info(:value='current', :no_edit='true')  
  loading-view(:value="loadingState")
</template>

<script>
import formmixin from '../../components/form'

export default {
  mixins: [formmixin],
  data() {
    return {
      loadingState: false,
      active: 0,
      current: {},
      option1: [],
    }
  },
  watch: {
    value(newV) {
      this.init(newV)
    }
  },
  computed: {
  },
  methods: {
    onChange(value) {
      this.current = this.option1[value].origin
    },
    async init(id) {
      if(id) {
        this.loadingState =  true
        let params = {
          where: {
            log_to: id,
          },
          with: {
            relation: [
              {
                table: "quote_item",
                key: "quote_poi_quote", 
                needcount: 0,
                order: "order"
              }
            ],
          }
        }
        let res = (await this.$api.GetQuote(params))
        this.option1 = res.data.items.map((item,index)=> {
          return {
            origin: item,
            text: `记录${index+1}  ${this.dataFormat(item.update_time)}`,
            value: index,
          }
        })
        if(this.option1.length > 0) {
          this.current = this.option1[0].origin
        }
        this.loadingState = false
      }
    },
  },
  mounted() {
    let id = this.$route.query.id
    this.init(id)
    this.$emit('on-nav', {
      leftText: '返回',
      leftArrow: true,
      onClickLeft: () => {
        this.$router.go(-1)
      },
      rightText: '主页',
      rightArrow: true,
      onClickRight: () => {
        this.$router.push({name: 'home'})
      }
    })
  },
}
</script>